import React from "react";
import Nav from "./nav";
import img from "../assets/mb.jpg";
import img2 from "../assets/mob.jpg";
import { BsSend } from "react-icons/bs";
import kobi from "../../../../assets/images/mascot.png";
import { IoMdCheckboxOutline } from "react-icons/io";

export default function Header() {
  return (
    <>
      <header
        className="w-full lg:block sm:hidden block xl:h-screen min-h-[50vh] bg-cover bg-center p-4"
        style={{ backgroundImage: `url(${img})` }}
      >
        <Nav />
        <div className="flex lg:justify-between justify-center items-end lg:items-center my-8 w-full min-h-[40vh]">
          {/* <div className="w-[34%] lg:flex hidden">
          <h1 className="xl:text-7xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl text-black font-bold">
            Healthcare That Comes to You on your Schedule.
          </h1>
          </div> */}
          <p className="sm:hidden text-xl font-bold text-center">
            Healthcare That Comes to You on your Schedule.
          </p>
          <div className="p-4 lg:block hidden w-[34%]">
            <h1 className="xl:text-7xl lg:text-6xl md:text-6xl sm:text-5xl text-3xl text-black font-bold">
              Healthcare That Comes to You on your Schedule
            </h1>
            {/* <div className="my-4">
              <ul className="gap-3">
                {services.map((i, idx) => (
                  <li
                    className="flex text-black md:text-lg text-sm font-medium gap-2 items-center"
                    key={idx}
                  >
                    <IoMdCheckboxOutline className="text-black" /> {i}
                  </li>
                ))}
              </ul>
            </div> */}
          </div>
          <div className="w-[25%] md:block hidden">
            <form action="" className="flex flex-col gap-4">
              <textarea
                required
                name="symptoms"
                id=""
                placeholder={`Enter your symptoms, e.g. "I have a headache nausea."`}
                className="w-full h-44 p-3 outline-none rounded-lg"
              ></textarea>

              <button className="px-5 py-2 flex justify-between items-center my-4 bg-white rounded-lg text-lg font-semibold">
                <span className="md:h-10 h-8 py-1 px-3 flex justify-center items-center bg-[#FFCC08] rounded-full">
                  <img src={kobi} alt="care" className=" h-full rounded-l-lg" />
                </span>
                Chat with Kobikuul <BsSend className="text-2xl" />
              </button>
            </form>
          </div>
        </div>
      </header>

      <header
        className="w-full sm:block lg:hidden hidden min-h-[50vh] bg-cover bg-center p-4"
        style={{ backgroundImage: `url(${img2})` }}
      >
        <Nav />
        <div className="flex justify-between items-center my-8 w-full min-h-[40vh]">
          {/* <div className="w-[34%] lg:flex hidden">
          <h1 className="xl:text-7xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl text-black font-bold">
            Healthcare That Comes to You on your Schedule.
          </h1>
        </div> */}
          <div className="p-4 md:w-[40%] w-[50%]">
            <h1 className="xl:text-7xl lg:text-6xl md:text-6xl sm:text-5xl text-4xl text-black font-bold">
              How are you today?
            </h1>
            <div className="my-4">
              <ul className="gap-3">
                {services.map((i, idx) => (
                  <li
                    className="flex text-black md:text-lg text-sm font-medium gap-2 items-center"
                    key={idx}
                  >
                    <IoMdCheckboxOutline className="text-black" /> {i}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="w-[25%] lg:block hidden">
            <form action="" className="flex flex-col gap-4">
              <textarea
                required
                name="symptoms"
                id=""
                placeholder={`Enter your symptoms, e.g. "I have a headache nausea."`}
                className="w-full h-44 p-3 outline-none rounded-lg"
              ></textarea>

              <button className="px-5 py-2 flex justify-between items-center my-4 bg-white rounded-lg text-lg font-semibold">
                <span className="md:h-10 h-8 py-1 px-3 flex justify-center items-center bg-[#FFCC08] rounded-full">
                  <img src={kobi} alt="care" className=" h-full rounded-l-lg" />
                </span>
                Chat with Kobikuul <BsSend className="text-2xl" />
              </button>
            </form>
          </div>
        </div>
      </header>
    </>
  );
}

const services = [
  "Online Consultations",
  "E-Prescriptions",
  "Health Information",
  "Medication Delivery",
  "Medical Records",
  "Chatbot",
  "Full Package Medical Insurance",
  "Trauma, Hospitalization and Life",
];
